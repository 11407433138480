.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl {
    min-width: 0;
    border-radius: 25px;
    padding: 8px 16px;
}

.MuiDialogTitle-root {
    padding: 0;
}

.MuiDialogContent-root {
    padding: 10px 40px !important;
}

::placeholder {
    /* Most modern browsers support this now. */
    color: #4D5766;
}

input[placeholder],
[placeholder],
*[placeholder] {
    color: #4D5766 !important;
}

::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: #4D5766;
}

:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #4D5766;
    opacity: 1;
}

::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #4D5766;
    opacity: 1;
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #4D5766;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #4D5766;
}

::placeholder {
    /* Most modern browsers support this now. */
    color: #4D5766;
}

.MuiRadio-colorSecondary.Mui-checked {
    color: #3D7DDD !important;
}