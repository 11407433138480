.MuiTab-root {
    padding: 0px 20px !important;
    border-radius: 10px 10px 0px 0px !important;
    background: #f3f3f36b !important;
    margin-right: 5px !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 17px !important;
    font-family: 'Inter' !important;
    color: #4D5766 !important;
    height: 51px !important;
}

/* .Mui-selected {
    background: #3D7DDD !important;
    color: white !important;
} */