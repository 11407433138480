.fc-toolbar-chunk .fc-button-primary{
    border: 1px solid #3D7DDD;
    padding: 8px 20px;
    background: #fff;
    border-radius: 15px;
    text-transform: capitalize;
}
.fc-button-group .fc-button-primary {
    color: #3D7DDD;
}
.fc-button-active, .fc-today-button{
    color: #fff;
    border: 1px solid #3D7DDD !important;
    padding: 8px 20px;
    background: #3D7DDD !important;
    border-radius: 15px;
}

.fc-Events-button:hover, .fc-button-group .fc-button:hover{
    border: 1px solid #3D7DDD !important;
    background: #3D7DDD !important;
}
.fc .fc-toolbar.fc-header-toolbar{
    margin-bottom: 2.5em;
}
.fc-toolbar-chunk .fc-Events-button{
    color: #3D7DDD;
}