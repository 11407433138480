$primaryColor: var(--primaryColor);
$buttonHoverColor: var(--buttonHoverColor);
$sidebarColor: var(--sidebarColor);
$bodyColor: var(--bodyColor);
$secondryColor: var(--secondryColor);
$contentColor: var(--contentColor);
$headerColor: var(--headerColor);
$successMessageBGColor: var(--successMessageBGColor);
$sidebarActive: var(--sidebarActive);
$labelcolor: var(--labelcolor);
$h1Size: var(--h1Size);
$h2Size: var(--h2Size);
$h3Size: var(--h3Size);
$pSize: var(--pSize);
$errorText: #ff0000f2;
$darkThemeText: var(--darkThemeText);

:export {
    primaryColor: $primaryColor;
    buttonHoverColor: $buttonHoverColor;
    sidebarColor: $sidebarColor;
    bodyColor: $bodyColor;
    secondryColor: $secondryColor;
    successMessageBGColor: $successMessageBGColor;
    contentColor: $contentColor;
    headerColor: $headerColor;
    labelcolor: $labelcolor;
    sidebarActive: $sidebarActive;
    h1Size: $h1Size;
    h2Size: $h2Size;
    h3Size: $h3Size;
    pSize: $pSize;
    errorText: $errorText;
    darkThemeText: $darkThemeText
}