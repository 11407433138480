@import './variable.scss';

.Toastify__toast-theme--light {
  background-color: $successMessageBGColor;
}

.action-button:hover{
  background: #048111 !important;
}

#filterreport h2 {
  text-align: center;
  font-size: 16px !important;
  font-weight: bolder !important;
  line-height: 0;
}
.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl{
  border: 1px solid #838383;
}
.MuiButton-root.Mui-disabled{
  color: #fff !important;
  background-color: #6c9ae0;
}

.react-tel-input .flag-dropdown{
  background-color: $contentColor;
  border-color: #838383 !important;
  border-radius: 25px 0 0 25px !important;
}
.react-tel-input .form-control{
  border-radius: 25px !important;
  padding: 25px 48px;
  background-color: $contentColor;
  border-color: #838383 !important;
}

.onBordingStepHead {
  width: 160px;
  max-width: 184px;
  cursor: pointer;
  height: 40px;
  position: relative;
  background: #D9D9D9;
}
.onBordingStepHeadDisabled {
  width: 185px;
  max-width: 185px;
  cursor: pointer;
  height: 40px;
  position: relative;
  background: #D9D9D9;
}
.onBordingStepHead:hover{
  background-color: #393939;
}
.onBordingStepHead:hover .customStepLabel{
  color: #fff;
}
.onBordingStepHead:hover .arrow-right{
  border-left-color: #393939;
}

.onBordingStepHeadDisabled.activeStepHead{
  background-color: #3D7DDD;
  color: #fff;
}
.onBordingStepHeadDisabled .arrow-right {
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent; /* 40px height (20+20) */
  border-left: 13px solid #D9D9D9;
  position: absolute;
  right: -13px;
  z-index: 9999;
}

.onBordingStepHeadDisabled .arrow-left {
  left: 0;
  width: 0;
  height: 0; 
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent; 
  position: absolute;
  border-left: 13px solid #fff; 
}
.muicc-colorinput-raw .muicc-colorbox-input{
  border: none !important;
  padding: 5px !important;
  min-width: 0px !important;
}
.colorPkrInput .ColorPicker-MuiInput-root{
  min-width: 0px !important;
  border: 1px solid #8E8EA1 !important;
  padding: 9px 14px !important;
  border-radius: 5px !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  width: 140px;
  border-left: 0px;
}

.colorPkrInput .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl{
  border: 1px solid #8E8EA1 !important;
  padding: 9px 14px !important;
  border-radius: 5px !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  width: 140px;
  border-left: 0px;
}

.colorPkrInput .muicc-colorpicker-button div{
  margin: 0px !important;
  border: 1px solid #8E8EA1 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  height: 37px !important;
  border-right: 0px !important;
  width: 37px !important;
}
.colorPkrInput .muicc-colorpicker-button {
  margin: 6px 0 !important;
  min-width: 37px !important;
}
.dateRangeWrapper .MuiPaper-elevation5{
  padding: 10px;
  box-shadow: none;
}
.dateRangeWrapper .MuiInput-root{
  padding: 7px;
  margin: 6px;
}
.dateRangeWrapper .MuiInput-root.MuiInput-underline:after{
  content: none;
}
.dateRangeWrapper .MuiInput-root.MuiInput-underline::before{
  content: none;
}
.dateRangeWrapper ul.MuiList-padding{
  display: none;
}
.dateRangeWrapper ul.MuiMenu-list{
  display: block;
}
.timePickerStyle .MuiInput-root.MuiInput-underline::before{
  content: none;
}
.timePickerStyle .MuiInput-root.MuiInput-underline::after{
  content: none;
}
.fc-daygrid-day-events a.fc-daygrid-event{
  overflow: hidden;
}
.fc-daygrid-day-events .evt-bg-color1{
  background-color: rgb(247, 247, 197);
}
.fc-daygrid-day-events .evt-bg-color2{
  background-color: rgb(5, 242, 255);
}
.fc-daygrid-day-events .evt-bg-color3{
  background-color: rgb(194, 162, 246);
}
.fc-daygrid-day-events .evt-bg-color4{
  background-color: rgb(209, 245, 0);
}
.fc-daygrid-day-events .evt-bg-color5{
  background-color: rgb(81, 187, 222);
}

// .MuiDialog-root{
//   z-index: 99999 !important;
// }
.MuiDataGrid-main .MuiIconButton-colorPrimary{
  background: none;
}
.hoverColr {
  background: rgba(2, 51, 125, 0.6);
  display: none;
  position: absolute;
  width: 100%;
  height: 300px;
  border-radius: 6.53218px;
}
.courseTitle {
  position: absolute;
  color: #FFFFFF;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.2px;
  top: 220px;
  left: 16px;
  width: 150px
}
.courseDesc {
  visibility: hidden;
  transition: all 300ms ease-in-out;
  z-index: 999;
  position: absolute;
  color: #FFFFFF;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.2px;
  top: 50px;
  left: 16px;
}

.Toastify__toast-container--top-right{
  z-index: 99999;
}