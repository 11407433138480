.customInputBox .MuiAutocomplete-inputRoot {
    border-radius: 8px;
    min-width: 100px;
    width: 100%;
}

.customInputBox .MuiInput-formControl {
    margin-top: 0;
}
.customInputBox .MuiFormLabel-root{
    top: -6px;
    left: 10px;
    font-size: 14px;
}
.customInputBox .MuiFormLabel-root.Mui-focused {
    top: -15px !important
}
.customInputBox .MuiFormLabel-root.MuiFormLabel-filled {
    top: -15px !important
}